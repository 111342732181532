@import url('https://fonts.googleapis.com/css2?family=Epilogue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

 .privacy-policy-container {
	 padding-top: 27px;
	 padding-bottom: 86px;
	 display: flex;
	 justify-content: center;
	font-family: 'Epilogue';
}
 .privacy-policy-container .terms-conditions-container {
	 max-width: 762px;
}
 .privacy-policy-container .terms-conditions-container h1 {
	 font-style: normal;
	 font-weight: 700;
	 font-size: 60px;
	 line-height: 72px;
	 text-align: center;
	 margin-bottom: 71px;
	 letter-spacing: -1.98px;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container h1 {
		 font-size: 32px;
		 line-height: 46px;
		 margin-bottom: 25px;
	}
}
 .privacy-policy-container .terms-conditions-container .f16-28 {
	 font-size: 16px;
	 line-height: 28px;
	 font-weight: 400;
	 color: #0d152e;
	 letter-spacing: -0.528px;
}
 @media (max-width: 1024px) {
	 .privacy-policy-container .terms-conditions-container .f16-28 {
		 text-align: justify;
	}
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .f16-28 {
		 font-size: 14px;
		 line-height: 24px;
		 text-align: justify;
	}
}
 .privacy-policy-container .terms-conditions-container .f16-28 .bold-text {
	 font-weight: 700;
}
 .privacy-policy-container .terms-conditions-container .note {
	 font-size: 20px;
	 line-height: 27.25px;
	 font-weight: 600;
	 font-family: 'Epilogue';
	 color: #0d152e;
	 margin-bottom: 10px;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .note {
		 font-size: 18px;
		 line-height: 24.25px;
		 text-align: justify;
	}
}
 .privacy-policy-container .terms-conditions-container .contents-head {
	 font-size: 24px;
	 line-height: 31.25px;
	 font-weight: 700;
	 color: #0d152e;
	 margin-bottom: 33px;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .contents-head {
		 font-size: 20px;
		 line-height: 22.25px;
		 text-align: justify;
	}
}
 .privacy-policy-container .terms-conditions-container .questions {
	 font-size: 18px;
	 line-height: 27.25px;
	 font-weight: 500;
	 font-family: 'Epilogue';
	 color: #0d152e;
	 margin-bottom: 7px;
	 text-decoration: none;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .questions {
		 font-size: 14px;
		 line-height: 23.25px;
	}
}
 .privacy-policy-container .terms-conditions-container .que-info .que-head {
	 font-size: 24px;
	 line-height: 31.25px;
	 font-weight: 700;
	 font-family: 'Epilogue';
	 color: #0d152e;
	 margin-bottom: 24px;
}
 @media (max-width: 1024) {
	 .privacy-policy-container .terms-conditions-container .que-info .que-head {
		 text-align: justify;
	}
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .que-info .que-head {
		 font-size: 17px;
		 line-height: 25.25px;
		 text-align: justify;
	}
}
 .privacy-policy-container .terms-conditions-container .que-info .sub-head {
	 font-size: 18px;
	 line-height: 28.25px;
	 font-weight: 600;
	 font-family: 'Epilogue';
	 color: #0d152e;
	 margin-bottom: 20px;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .que-info .sub-head {
		 font-size: 18px;
		 line-height: 26.25px;
		 text-align: justify;
	}
}
 .privacy-policy-container .terms-conditions-container .que-info .in-short-head {
	 font-size: 16px;
	 line-height: 28.25px;
	 font-weight: 600;
	 font-family: 'Epilogue';
	 font-style: italic;
	 color: #0d152e;
	 margin-bottom: 20px;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .que-info .in-short-head {
		 text-align: justify;
	}
}
 .privacy-policy-container .terms-conditions-container .que-info .in-short-italic {
	 margin-left: 10px;
	 font-size: 16px;
	 line-height: 28.25px;
	 font-weight: 500;
	 font-family: 'Epilogue';
	 font-style: italic;
	 color: #0d152e;
	 margin-bottom: 20px;
}
 .privacy-policy-container .terms-conditions-container .que-info .light-italic {
	 font-size: 16px;
	 line-height: 28.25px;
	 font-weight: 400;
	 font-family: 'Epilogue';
	 font-style: italic;
	 color: #0d152e;
}
 .privacy-policy-container .terms-conditions-container .que-info .in-short {
	 margin-left: 10px;
	 font-size: 16px;
	 line-height: 28.25px;
	 font-weight: 400;
	 font-family: 'Epilogue';
	 color: #0d152e;
	 margin-bottom: 20px;
}
 .privacy-policy-container .terms-conditions-container .que-info .que-describe {
	 font-size: 16px;
	 line-height: 28.25px;
	 font-weight: 400;
	 font-family: 'Epilogue';
	 color: #0d152e;
	 margin-bottom: 20px;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .que-info .que-describe {
		 text-align: justify;
	}
}
 @media (max-width: 320px) {
	 .privacy-policy-container .terms-conditions-container .que-info .que-describe {
		 font-size: 14px;
		 line-height: 26.25px;
		 text-align: justify;
	}
}
 .privacy-policy-container .terms-conditions-container .t-mb-41 {
	 margin-bottom: 41px;
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .t-mb-41 {
		 margin-bottom: 10px;
	}
}
 @media (max-width: 599px) {
	 .privacy-policy-container .terms-conditions-container .br {
		 display: contents;
	}
}
 