@tailwind base;

@tailwind components;

@tailwind utilities;

/* @import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities"; */

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #cacaca;
  font-family: "epilogue";
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cacaca;
  border-radius: 14px;
  border: 3px solid none;
}

/* POST YOUR JOB */
#city ~ ul {
  /* display: none; */
  max-height: 0;
  border: transparent;
  border-radius: 4px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
#city:focus ~ ul {
  /* display: block; */
  max-height: 150px;
  border: 1px solid rgb(194, 194, 194);
}

.job-description-container .job-description-text a {
  color: blue;
  font-weight: bold;
}

.footer {
  text-align: center;
  width: 100%;
  background-color: #fff;
}

.footer-container {
  overflow: hidden;
  /* padding: 20px; */
  padding: 0;
  margin: 0;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.left-footer,
.right-footer {
  flex: 1 0 49%;
}

.left-footer-title,
.right-footer-title {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 10px;
}

.left-footer-content,
.left-footer-content-new,
.right-footer-content {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 15px;
}

.left-footer-content,
.left-footer-content-new {
  border-right: 1px solid #cccccc;
  padding-right: 40px;
}

.left-footer-images,
.right-footer-item {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-image {
  max-width: 100%;
}

.img-left {
  width: 250px;
  margin-right: 37px;
}

.img-left-small {
  width: 50px;
}

.img-large {
  width: 90px;
}

.footer-text {
  text-align: left;
}

.footer-bold {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.footer-subtext {
  font-size: 14px;
  color: #6c757d;
}

/* Responsive Styles */
/* @media (min-width: 1200px) and (max-width: 1310px) {
  .footer-image {
    width: 60px !important;
  }
} */

@media (max-width: 700px) {
  .footer-image {
    width: 50px !important;
  }

  .right-footer-content,
  .left-footer-content,
  .left-footer-content-new {
    flex-wrap: wrap;
  }

  .img-left {
    width: 200px !important;
    padding-bottom: 10px;
  }
}

@media (max-width: 1271px) {
  .left-footer-content {
    border-right: none;
    border-bottom: 1px solid #cccccc;
  }
}

@media (max-width: 998px) {
  .left-footer-content-new {
    border-right: none;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
  }
}

@media (max-width: 1200px) {
  .left-footer-content {
    padding: 0 40px 15px;
    border-right: none;
    border-bottom: 1px solid #cccccc;
  }

  .left-footer-title,
  .right-footer-title {
    white-space: normal;
  }

  .left-footer,
  .right-footer {
    padding: 0;
  }

  .footer-container {
    overflow: hidden;
  }
}
