.TermandCondition {
    padding-top: 27px;
    padding-bottom: 86px;
    display: flex;
    justify-content: center;
}
.TermandCondition .terms-container {
    max-width: 762px;
}
.TermandCondition .terms-container h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    margin-bottom: 71px;
    letter-spacing: -1.98px;
}
@media (max-width: 599px) {
    .TermandCondition .terms-container h1 {
        font-size: 32px;
        line-height: 46px;
        margin-bottom: 25px;
   }
}
.TermandCondition .terms-container .detail {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #0d152e;
    letter-spacing: -0.528px;
}
@media (max-width: 1024px) {
    .TermandCondition .terms-container .detail {
        text-align: justify;
   }
}
@media (max-width: 599px) {
    .TermandCondition .terms-container .detail {
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
   }
}
.TermandCondition .terms-container .dark-font {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #0d152e;
    letter-spacing: -0.528px;
}
@media (max-width: 599px) {
    .TermandCondition .terms-container .dark-font {
        font-size: 16px;
        line-height: 20px;
   }
}
