/* #__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vh;
}

@media screen and (max-width: 320px) {
  #__next {
    display: flex
  }
} */

.about-container {
  padding-top: 85px;
  padding-bottom: 30px;
}
@media (max-width: 599px) {
  .about-container {
    padding-top: 45px;
    padding-bottom: 40px;
    text-align: center;
    text-align: -webkit-center;
  }
}
.about-container h1 {
  max-width: 736px;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -1.98px;
  color: #000;
}
@media (max-width: 768px) {
  .about-container h1 {
    font-size: 52px;
    line-height: 52px;
  }
}
@media (max-width: 599px) {
  .about-container h1 {
    font-size: 31px;
    line-height: 44px;
  }
}
@media (max-width: 360px) {
  .about-container h1 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 320px) {
  .about-container h1 {
    font-size: 27px;
    line-height: 34px;
  }
}

.about-container blockquote {
  font-weight: 100;
  font-size: 2rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 0.5rem;
}

.about-container blockquote:before,
.about-container blockquote:after {
  position: absolute;
  color: #f1efe6;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

.about-container blockquote:before {
  content: "“";
  left: -3rem;
  top: 2rem;
}

.about-container blockquote:after {
  content: "”";
  right: -3rem;
  bottom: -1rem;
}

.about-container .sub-head-text {
  max-width: 697px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #81838c;
  text-align: center;
  letter-spacing: -0.66px;
  margin-top: 26px;
}

.sub-head-text.quote {
  font-size: 22px;
  color: #5b5c61;
}

@media (max-width: 599px) {
  .about-container .sub-head-text {
    font-size: 18px;
    line-height: 26px;
    text-align: justify;
  }
}
.about-container .top-imgs {
  display: flex;
  justify-content: center;
  margin-top: 108px;
}
@media (max-width: 768px) {
  .about-container .top-imgs {
    margin-top: 52px;
  }
}
@media (max-width: 599px) {
  .about-container .top-imgs .padding-remove {
    padding-top: 0px;
  }
}
.about-container .top-imgs .img-container {
  max-width: 1110px;
}
.about-container .top-imgs .img-container .MuiAvatar-root {
  width: auto;
  height: auto;
}
.about-container .top-imgs .img-container .htop-1 {
  margin-bottom: 47px;
}
.about-container .top-imgs .img-container .htop-2 {
  margin-top: 47px;
}
@media (max-width: 768px) {
  .about-container .top-imgs .img-container .htop-2 {
    margin-top: 0px;
  }
}
.about-container .empower-section {
  display: flex;
  justify-content: center;
  margin: 115px 0px;
}
@media (max-width: 768px) {
  .about-container .empower-section {
    margin: 34px 0px;
  }
}
@media (max-width: 599px) {
  .about-container .empower-section .MuiGrid-item {
    padding-top: 0px;
  }
}
.about-container .empower-section .empower-container {
  max-width: 1110px;
}
.about-container .empower-section .empower-container .recue-title {
  display: block;
  text-align: center;
}
.about-container .empower-section .empower-container h4 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1.584px;
  color: #0d152e;
}
@media (max-width: 834px) {
  .about-container .empower-section .empower-container h4 {
    font-size: 42px;
    line-height: 55px;
  }
}
@media (max-width: 768px) {
  .about-container .empower-section .empower-container h4 {
    font-size: 40px;
    line-height: 40px;
  }
}
@media (max-width: 599px) {
  .about-container .empower-section .empower-container h4 {
    font-size: 34px;
    line-height: 38px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
@media (max-width: 360px) {
  .about-container .empower-section .empower-container h4 {
    font-size: 32px;
    line-height: 35px;
  }
}
.about-container .empower-section .empower-container .desc {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.66px;
  color: #81838c;
  padding-right: 50px;
}
@media (max-width: 768px) {
  .about-container .empower-section .empower-container .desc {
    padding-right: 0px;
  }
}
@media (max-width: 599px) {
  .about-container .empower-section .empower-container .desc {
    font-size: 18px;
    margin-top: 2px;
    text-align: justify;
  }
}
@media (max-width: 599px) {
  .about-container .empower-section .empower-container .desc .br {
    display: contents;
  }
}
.about-container .team-section {
  display: flex;
  justify-content: center;
}
.about-container .team-section .div {
  width: 100%;
  max-width: 862px;
}
.about-container .team-section .MuiAvatar-img {
  width: 267px;
  height: 399.9px;
}
.about-container .team-section h3 {
  font-family: "DM Sans";
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1.584px;
  color: #0d152e;
  margin-bottom: 24px;
}
@media (max-width: 599px) {
  .about-container .team-section h3 {
    font-size: 38px;
    line-height: 45px;
  }
}
.about-container .team-section .desc {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.66px;
  color: #81838c;
  max-width: 610px;
  margin-bottom: 46px;
}
@media (max-width: 599px) {
  .about-container .team-section .desc {
    text-align: justify;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 35px;
  }
}
.about-container .team-section .team-container {
  width: 100%;
}
.about-container .team-section .team-container .MuiAvatar-root {
  width: auto;
  height: auto;
}
.about-container .team-section .team-container .name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.726px;
  margin-top: 30px;
  margin-bottom: 9px;
  color: #0d152e;
}
@media (max-width: 599px) {
  .about-container .team-section .team-container .name {
    margin-top: 16px;
    margin-bottom: 6px;
    font-size: 20px;
  }
}
@media (max-width: 834px) {
  .about-container .team-section .team-container .name {
    text-align: center;
  }
}
.about-container .team-section .team-container .position {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.528px;
  color: #81838c;
}
@media (max-width: 599px) {
  .about-container .team-section .team-container .position {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 834px) {
  .about-container .team-section .team-container .position {
    text-align: center;
  }
}
.bluebg .download-info .download-app .download-head {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  /* or 125% */
  letter-spacing: -1.584px;
  color: #fff;
  max-width: 399px;
  margin: 141px auto 38.5px 80px;
}
@media (max-width: 1024px) {
  .bluebg .download-info .download-app .download-head {
    margin: 141px auto 38.5px 0px;
  }
}
@media (max-width: 599px) {
  .bluebg .download-info .download-app .download-head {
    margin: 84px 0px 34.5px 0px;
  }
}
@media (max-width: 834px) {
  .bluebg .download-info .download-app .download-head {
    margin: 141px auto 34.5px 0px;
    text-align: center;
  }
}
@media (max-width: 899px) {
  .bluebg .download-info .download-app .download-head {
    max-width: inherit;
    font-size: 45px;
    line-height: 55px;
  }
}
.bluebg .download-info .download-app .download-head .MuiGrid-item {
  margin-top: 117px;
}
@media (max-width: 599px) {
  .bluebg .download-info .download-app .download-head {
    font-size: 41px;
    line-height: 48px;
    margin-bottom: 25.5px;
  }
}
@media (max-width: 360px) {
  .bluebg .download-info .download-app .download-head {
    font-size: 35px;
    line-height: 44px;
    margin-bottom: 25.5px;
  }
}
.bluebg .download-info .download-app .store-links {
  display: flex;
  justify-content: flex-start;
  margin-left: 80px;
}
@media (max-width: 1024px) {
  .bluebg .download-info .download-app .store-links {
    margin-left: 0px;
    display: block;
  }
}
@media (max-width: 834px) {
  .bluebg .download-info .download-app .store-links {
    margin-left: 75px;
  }
}
@media (max-width: 810px) {
  .bluebg .download-info .download-app .store-links {
    margin-left: 67px;
  }
}
@media (max-width: 768px) {
  .bluebg .download-info .download-app .store-links {
    margin-left: 45px;
  }
}
@media (max-width: 480px) {
  .bluebg .download-info .download-app .store-links {
    margin-left: 0px;
  }
}
@media (max-width: 360px) {
  .bluebg .download-info .download-app .store-links {
    margin-left: 0px;
  }
}
@media (max-width: 599px) {
  .bluebg .download-info .download-app .store-links {
    display: block;
    text-align: center;
  }
}
@media (max-width: 360px) {
  .bluebg .download-info .download-app .store-links {
    display: block;
  }
}
.bluebg .download-info .download-app .store-links .app-store {
  margin-right: 19.38px;
}
@media (max-width: 599px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-right: 0px;
  }
}
@media (max-width: 480px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-right: 8px;
  }
}
@media (max-width: 414px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-right: 0px;
  }
}
@media (max-width: 412px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-left: 0px;
  }
}
@media (max-width: 390px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-left: 0px;
  }
}
@media (max-width: 379px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-left: 0px;
  }
}
@media (max-width: 375px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-left: 0px;
  }
}
@media (max-width: 360px) {
  .bluebg .download-info .download-app .store-links .app-store {
    margin-right: 0px;
  }
}
@media (max-width: 360px) {
  .bluebg .download-info .download-app .store-links .play-store {
    margin-left: 0px;
  }
}
.bluebg .download-img {
  width: auto;
  height: auto;
  margin-bottom: -2px;
  max-width: 505px;
  max-height: 404px;
  margin-top: 103px;
}
